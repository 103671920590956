import React from 'react';
import { Layout } from 'components';
import './legal-returns-app.scss';

const LegalPrivacy = () => {
  return (
    <Layout>
      <div className="wrapper">
        <div className="legal-returns-app">
          <h1 className="legal-returns-app__title">
            <strong>Privacy Policy</strong>
          </h1>
          <p>&nbsp;</p>
          <p>Last Updated: 25th May 2018</p>
          <p>
            Maxwell Forest Pty Ltd (“we”, “us”, “our”) is committed to protecting the privacy of individuals (“you”,
            “your”, “user”, “user’s”, “users”). We have prepared this Privacy Policy to describe to you our practices
            regarding the Personal Data we collect from users of our website, located at www.safedome.com
            (http://www.safedome.com) and any other website created or hosted by us from time to time on which this
            Privacy Policy appears; our mobile application (“App(s)”); our widgets (collectively, the “Site”) and online
            services (“Services”).
          </p>
          <h3>PARTICULARLY IMPORTANT INFORMATION</h3>
          <p>&nbsp;</p>
          <h4>Who We Are:</h4>
          <p>
            For the purpose of applicable data protection legislation, the data controller of your personal data is
            Maxwell Forest Pty Ltd, with a principal place of business at Lvl 1; 85 William Street, Darlinghurst,
            Sydney, Australia, 2010 and your EU Representative is customercare@safedome.com.
          </p>
          <p>
            Subject to the next paragraph, we ask that you not send us, and you do not disclose special categories of
            data as defined in the General Data Protection Regulation: 2018 (information related to racial or ethnic
            origin, political opinions, religious or physiological beliefs, health, biometrics or genetic
            characteristics, criminal background or trade union membership or data concerning data subject’s sex life or
            sexual orientation) (“Special Categories of Personal Data”) on or through our Website, the Site or Services
            or otherwise to us.
          </p>
          <h4>Changes to Privacy Policy:</h4>
          <p>We will post any modifications or changes to the Privacy Policy on our Site and Services.</p>
          <p>
            We reserve the right to modify the Policy at any time, so we encourage you to review it frequently. The
            “Last Updated” legend above indicates when this Policy was last changed. If we make any material changes in
            the way we use your Personal Data, we will notify you by sending you an e-mail to the last e-mail address
            you provided to us and/or posting notice of the changes on our Site prior to such change(s) taking effect.
          </p>
          <h4>Questions; Contacting Company; Reporting Violations.</h4>
          <p>
            If you have any questions or concerns or complaints about our Privacy Policy or our data collection or
            processing practices, or if you want to report any security violations to us, please contact us:
          </p>
          <p>Maxwell Forest Pty Ltd</p>
          <p>Attn: Customer Care</p>
          <p>Lvl 1, 85 William Street, Darlinghurst, Sydney, Australia, 2010</p>
          <p>customercare@safedome.com</p>
          <p>&nbsp;</p>
          <h4>Complaints.</h4>
          <p>
            We are committed to resolving any complaints about our collection or use of your Personal Data. If you would
            like to make a complaint regarding this Policy or our practices in relation to your personal data, please
            contact us at customercare@safedome.com. We will reply to your complaint as soon as we can and in any event,
            within 45 days. We hope to resolve any complaint brought to our attention; however, if you feel that your
            complaint has not been adequately resolved, you reserve the right to contact your local data protection
            supervisory authority.
          </p>
          <p>&nbsp;</p>
          <h4>A Note About Children.</h4>
          <p>
            We do not intentionally gather Personal Data from visitors who are under the age of 16. If a child under 16
            years old submits Personal Data to Company and we learn that the Personal Data is the information of a child
            under 16 years old, we will attempt to delete the information as soon as possible. If you believe that we
            might have any Personal Data from a child under 16 years old, please contact us at
            customercare@safedome.com.
          </p>
          <p>&nbsp;</p>
          <h4>A Note to Users Outside of Australia.</h4>
          <p>
            Your data, including Personal Data that we collect from you, may be transferred to, stored at and processed
            by us and our affiliates and other third parties outside the country in which you reside, including, but not
            limited to the Australia, where data protection and privacy regulations may not offer the same level of
            protection as in other parts of the world. We will take all steps reasonably necessary to ensure that your
            data is treated securely and in accordance with this policy.
          </p>
          <p>&nbsp;</p>
          <h4>Types of Data We Collect.</h4>
          <p>
            We collect information about you in a range of forms, including Personal Data. As used in this Privacy
            Policy,“Personal Data” is as defined in the General Data Protection Regulation: 2018 as any information
            relating to an identified or identifiable natural person (“data subject”); an identifiable natural person is
            one who can be identified, directly or indirectly, in particular by reference to an identifier such as a
            name, an identification number, location data, an online identifier or one or more factors specific to the
            physical, physiological, genetic, mental, economic, cultural or social identity of that natural
            person.“Anonymous Data” means data that does not relate to an identified or identifiable natural person or
            to personal data rendered anonymous in such a manner that the data subject is not or is no longer
            identifiable. Anonymous Data does not, by itself, permit the identification of individual persons. We
            collect Personal Data and Anonymous Data, as described below.
          </p>
          <p>&nbsp;</p>
          <h4>Information You Provide to Us.</h4>
          <p>
            *We collect information that you enter on our Site or otherwise provide to us, including when you make a
            purchase from us, request information, submit a review, or otherwise contact us. The type of information
            that we collect from you depends on your interaction with our Site and your use of our Services, but may
            include your: full name, mailing address, billing address, telephone number, email address, payment
            information (for purchases), location data, and any other information that you choose to provide.
          </p>
          <p>
            *If you use our Services on your mobile device, including through our App(s), we may collect your phone
            number and the unique device id number.
          </p>
          <p>
            *If you tell us where you are (e.g., by allowing your mobile device to send us your location), we may store
            and use that information to provide you with location- based information and advertising. If you want to
            deactivate this feature, you can either reinstall the App(s) or deactivate Bluetooth on your mobile device.
          </p>
          <p>
            *Our Site lets you store preferences like how your content is displayed, your location, safe search
            settings, and favourite widgets. We may associate these choices with your ID, browser or the mobile device,
            and you can edit these preferences at any time.
          </p>
          <p>
            *When connecting to our Services via a service provider that uniquely identifies your mobile device, we may
            receive this identification and use it to offer extended services and/or functionality.
          </p>
          <p>
            *Mobile Services. We may also collect information from your mobile device if you have downloaded our App(s).
            This information is generally used to help us deliver the most relevant information to you. Examples of
            information that may be collected and used include your geographic location, how you use the App(s), and
            information about the type of device you use. In addition, in the event our App(s) crash on your mobile
            device, we will receive information about your mobile device model software version and device carrier,
            which allows us to identify and fix bugs and otherwise improve the performance of our App(s). This
            information is sent to us as aggregated information and is not traceable to any individual and cannot be
            used to identify an individual. Note, we collect information about the location of your device but no
            personal information is associated with that information. In addition, if you choose to turn on your
            Bluetooth, Wi-Fi or other geolocation functionality when you use our mobile application, we may collect and
            use your geolocation information.
          </p>
          <p>
            *If you make a purchase from us, you will be required to provide a credit or debit card number and related
            financial information (such as CVV, expiration date, and billing address), or other payment information
            (such as via PayPal or Apple Pay), depending on the form of payment you choose. We have implemented
            commercially reasonable precautions regarding the information we collect to protect from loss, misuse, and
            unauthorized access, disclosure, alteration, and destruction, in accordance with industry best practices and
            applicable laws, including encryption both during transmission and at rest.
          </p>
          <p>
            *If you provide us feedback or contact us via email, we will collect your name and email address, as well as
            any other content included in the email, in order to send you a reply.
          </p>
          <p>*When you participate in one of our surveys, we may collect additional profile information.</p>
          <p>
            *We also collect other types of Personal Data that you provide to us voluntarily, such as your operating
            system and version, product registration number, and other requested information if you contact us via
            e-mail or our mobile app regarding support for the Services.
          </p>
          <p>&nbsp;</p>
          <h4>Information You Provide through Social Networking Sites.</h4>
          <p>
            You may also be given the option to link to your Facebook or other social media accounts through the
            Services. When you do, we may automatically receive certain information from you based on your privacy
            settings on those sites. This may include, but may not be limited to, name, username, demographic
            information, updated address or contact information, location, interests, and publicly-observed data, such
            as from social media.
          </p>
          <p>
            You can revoke our access to such information by amending the appropriate account setting on the applicable
            SNS.
          </p>
          <p>&nbsp;</p>
          <h4>Information We Collect Automatically.</h4>
          <p>
            We automatically collect the following information about your use of our Site or Services through cookies,
            web beacons, and other technologies: your domain name; your browser type and operating system; web pages you
            view; links you click; your IP address; the length of time you visit our Site and or use our Services; and
            the referring URL, or the webpage that led you to our Site. We use this information to enhance your online
            shopping experience and to enable us to provide you with an easier and more personalized level of service.
            On some of our Sites, we may combine this information with personal information that you have provided to
            us. Please see the section “Cookies and Other Tracking Mechanisms” below for more information.
          </p>
          <p>&nbsp;</p>
          <h4>Cookies and Other Tracking Mechanisms.</h4>
          <p>1. What are Cookies?</p>
          <p>
            We may collect information using “cookies.” Cookies are alphanumeric identifiers that are transferred to
            your computer’s hard drive through your web browser for record-keeping purposes. There are two types of
            cookies:
          </p>
          <p>
            (a) Session Cookies. Session cookies exist only during an online session. They disappear from your computer
            when you close your browser or turn off your computer. We use session cookies to allow our systems to
            uniquely identify you during a session or while you are logged into the Site. This allows us to process your
            online transactions and requests and verify your identity after you have logged in, as you move through our
            Site.
          </p>
          <p>
            (b) Persistent Cookies. Persistent cookies remain on your computer after you have closed your browser or
            turned off your computer. We use persistent cookies to track aggregate and statistical information about
            user activity and to display advertising both on our Site and on third-party sites.
          </p>
          <p>2. Cookies We Use</p>
          <p>
            We use two broad categories of cookies: (1) first-party cookies, served directly by use to your computer or
            mobile device, which are used only by us to recognize your computer or mobile device when it revisits our
            Site or Services and (2) third- party cookies, which are served by service providers on our Site or
            Services, and can be used by such service providers to recognize your computer or mobile device when it
            visits other websites.
          </p>
          <p>3. Disabling Cookies.</p>
          <p>
            Most web browsers automatically accept cookies, but if you prefer, you can edit your browser options to
            block them in the future. The “help” portion of the toolbar on most browsers will tell you how to prevent
            your computer from accepting new cookies, how to have the browser notify you when you receive a new cookie,
            or how to disable cookies altogether. Visitors to our Site who disable cookies will be able to browse
            certain areas of the Site, but some features may not function, such as the shopping cart.
          </p>
          <p>&nbsp;</p>

          <h4>Collection of Data by Third-Party Advertisers</h4>

          <p>1. Third Party Advertisers.</p>
          <p>
            We use third parties such as network advertisers and others to assist us in advertising, including
            displaying our advertisements on other websites and through social media platforms; we also may use network
            advertisers, third parties that display advertisements based on your visits to our Site as well as other
            websites, and other third parties to assist us in identifying relevant advertising on our Site. This enables
            us and these third parties to target advertisements by displaying ads for products and services in which you
            might be interested. Third party ad network providers, advertisers, sponsors and/or traffic measurement
            services may use cookies, JavaScript, web beacons (including clear GIFs), Flash LSOs and other technologies
            to measure the effectiveness of their ads and to personalize advertising content to you. These third party
            cookies and other technologies are governed by each third party’s specific privacy policy, not this one. We
            may provide these third-party advertisers with information about your usage of our Site and our Services, as
            well as anonymous information about visitors to our Site and users of our service.
          </p>
          <p>2. Analytics Services and Interest-Based Advertising.</p>
          <p>
            In order to serve offers and advertisements that may interest you, we may display targeted advertisements on
            the Site or Services or other digital applications or properties in connection with our content based on
            information provided to us by our users and information provided to us by third parties that they have
            independently collected. We do not provide Personal Data to advertisers when you interact with an
            advertisement. In addition to the tracking technologies we place, other companies may set their own cookies
            or similar tools when you visit our Site. This includes third party analytics services, including but not
            limited to Google Analytics (“Analytics Services”), that we engage to help analyze how users use the Site,
            as well as third parties that deliver content or offers. We may receive reports based on these parties’ use
            of these tools on an individual or aggregate basis. We use the information we get from Analytics Services
            only to improve our Site and Services. The information generated by the Cookies or other technologies about
            your use of our Site and Services (the&nbsp;“Analytics Information”) is transmitted to the Analytics
            Services. The Analytics Services use Analytics Information to compile reports on user activity. The
            Analytics Services may also transfer information to third parties where required to do so by law, or where
            such third parties process Analytics Information on their behalf. Each Analytics Services’ ability to use
            and share Analytics Information is restricted by such Analytics Services’ Terms of Use and Privacy Policy.
            By using our Site and Services, you consent to the processing of data about you by Analytics Services in the
            manner and for the purposes set out above.
          </p>

          <p>3. Location Information.</p>
          <p>
            If you have enabled location services on your phone, we collect your location information to make a map
            available to link your device to your location. If you do&nbsp;information to make a map available to link
            the device to your location. &nbsp;If you do not want this information collected by us, you can disable
            Location Services on your phone.
          </p>

          <p>4. How We Use Your Information</p>
          <ol>
            <li>
              <strong>Why do we need your Personal Data?</strong>
              <br />
              <span>
                We will only process your Personal Data in accordance with applicable data protection and privacy laws.
                We need certain Personal Data in order to provide you with access to the Site and/or Services. If you
                created a profile/registered with us, you will have been asked to check a tick box indicating your
                agreement to provide this data in order to access our services, purchase our products, view our content.
                This consent provides us with the legal basis we require under applicable law to process your data. You
                maintain the right to withdraw such consent at any time. If you do not agree to our use of your Personal
                Data in line with this policy, please do not use our Site and/or Services.
              </span>
            </li>

            <li>
              <strong>
                General Use
                <br />
                <span>
                  In general, Personal Data you submit to us is used either to respond to requests that you make or to
                  aid us in serving you better. We use your Personal Data in the following ways:
                </span>
              </strong>
              <ul>
                <li>facilitate the creation of and secure your Account on our network;</li>

                <li>identify you as a user in our system;</li>

                <li>provide improved administration of our Site and Services;</li>

                <li>provide the Services you request;</li>

                <li>improve the quality of experience when you interact with our Site and Services;</li>

                <li>
                  send you a welcome email to verify ownership of the email address provided when your Account was
                  created;
                </li>

                <li>
                  send you administrative email notifications, such as security or support and maintenance advisories;
                </li>

                <li>respond to your inquiries related to employment opportunities or other requests;</li>

                <li>
                  make telephone calls to you, from time to time, as a part of secondary fraud protection or to solicit
                  your feedback; and
                </li>

                <li>
                  send newsletters, surveys, offers, and other promotional materials related to our Services and for
                  other marketing purposes of Company.
                </li>

                <li>
                  User Testimonials and Feedback. We often receive testimonials and comments from users who have had
                  positive experiences with our Services. We occasionally publish such content. When we publish this
                  content, we may identify our users by their first and last name and may also indicate their home city.
                  We obtain the user’s consent prior to posting his or her name along with the testimonial. If users
                  make any comments on a blog or forum associated with our Site, they should be aware that any Personal
                  Data they submit there can be read, collected, or used by other users of these forums, and could be
                  used to send the users unsolicited messages. We are not responsible for the personally identifiable
                  information users choose to submit in these blogs and forums.
                </li>
                <li>
                  Creation of Anonymous Data Records. We may create Anonymous Data records from Personal Data by
                  excluding information (such as user’s names) that makes the data personally identifiable. We use this
                  Anonymous Data to analyse request and usage patterns so that we may enhance the content of our
                  Services and improve Site navigation. We reserve the right to use Anonymous Data for any purpose and
                  disclose Anonymous Data to third parties in our sole discretion.
                  <br />
                  <br />
                </li>
              </ul>
            </li>
          </ol>

          <h4>
            <p>How We Share Collected Data</p>
          </h4>
          <ol>
            <li>
              <strong>Third Party Service Providers.</strong>
              <br />
              <p>
                We may share your Personal Data with third party service providers to provide you with the Services that
                we offer you through our Site; to conduct quality assurance testing; to facilitate the creation of
                accounts; to provide technical support; and/or to provide other services to the Company.
              </p>
            </li>
            <li>
              <strong>Corporate Restructuring.</strong>
              <br />
              <p>
                We may share some or all of your Personal Data in connection with or during the negotiation of any
                merger, financing, acquisition or dissolution transaction or proceeding involving a sale, transfer,
                divestiture, or disclosure of all or a portion of our business or assets. In the event of an insolvency,
                bankruptcy, or receivership, Personal Data may also be transferred as a business asset. If another
                company acquires our company, business, or assets, that company will possess the Personal Data collected
                by us and will assume the rights and obligations regarding your Personal Data as described in this
                Privacy Policy.
              </p>
            </li>
            <li>
              <strong>Other Disclosures.</strong>
              <br />
              <p>
                Regardless of any choices you make regarding your Personal Data (as described below), Company may
                disclose Personal Data if it believes in good faith that such disclosure is necessary (a) in connection
                with any legal investigation; (b) to comply with relevant laws or to respond to subpoenas or warrants
                served on Company; (c) to protect or defend the rights or property of Company or users of the Site or
                Services; and/or (d) to investigate or assist in preventing any violation or potential violation of the
                law, this Privacy Policy, or our Terms of Use.
              </p>
            </li>
            <li>
              <strong>Third Party Websites.</strong>
              <br />
              <p>
                Our Site may contain links to third-party websites. When you click on a link to any other website or
                location, you will leave our Site and go to another site, and another entity may collect Personal Data
                or Anonymous Data from you. We have no control over, do not review, and cannot be responsible for, these
                outside websites or their content. Please be aware that the terms of this Privacy Policy do not apply to
                these outside websites or content, or to any collection of your Personal Data after you click on links
                to such outside websites. We encourage you to read the privacy policies of every website you visit. The
                links to third party websites or locations are for your convenience and do not signify our endorsement
                of such third parties or their products, content or websites.
              </p>
            </li>
            <li>
              <strong>Your Choices Regarding Your Personal Data.</strong>
              <br />
              <span>You have several choices regarding the use of your information on our Service:</span>

              <ul>
                <li>
                  <span className="legal-returns-app__para-title">(a) Email Communications.</span>
                  &nbsp;We will periodically send you e-mails that directly promote the use of our Site or Services.
                  When you receive newsletters or promotional communications from us, you may indicate a preference to
                  stop receiving further communications from us and you will have the opportunity to “opt-out” by
                  following the unsubscribe instructions provided in the e-mail you receive or by contacting us directly
                  (please see contact information below). Despite your indicated e-mail preferences, we may send you
                  service-related communications, including notices of any updates to our Terms of Use or Privacy
                  Policy.
                </li>
                <li>
                  <span className="legal-returns-app__para-title">(b) Cookies.</span>
                  &nbsp;If you decide at any time that you no longer wish to accept Cookies from our Service for any of
                  the purposes described above, then you can instruct your browser, by changing its settings, to stop
                  accepting Cookies or to prompt you before accepting a Cookie from the websites you visit. Consult your
                  browser’s technical information. If you do not accept Cookies, however, you may not be able to use all
                  portions of the Service or all functionality of the Service.
                </li>
                <li>
                  <span className="legal-returns-app__para-title">
                    (c) Changing or Deleting Your Personal Data.&nbsp;
                  </span>
                  You may review, update, correct or delete the Personal Data in your user account (including any
                  imported contacts) by contacting us. If you completely delete all of your Personal Data, then your
                  user account may become deactivated. We will use commercially reasonable efforts to honor your
                  request. We may retain an archived copy of your records as required by law or for legitimate business
                  purposes.
                </li>
                <li>
                  <span className="legal-returns-app__para-title">(d) Applications.</span>
                  &nbsp;You can stop all collection of information by the App(s) by uninstalling the App(s). You may use
                  the standard uninstall processes as may be available as part of your mobile device or via the mobile
                  application marketplace or network. You may at any time opt-out from further allowing us to have
                  access to your location data by disabling item history within the App.
                  <p></p>
                </li>
              </ul>
            </li>
          </ol>

          <p className="legal-returns-app__para-title">Your Rights</p>
          <ul>
            <li>
              <span className="legal-returns-app__para-title">(a) Opt-out.&nbsp;</span>
              You may contact us anytime to opt-out of (i) direct marketing communications; (ii) automated
              decision-making and/or profiling; (iii) our collection of sensitive personal data; (iv) any new processing
              of your Personal Data that we may carry out beyond the original purpose; or (v) the transfer of your
              Personal Data outside the EEA. Please note that your use of some of the Site and/or Services may be
              ineffective upon opt-out.
            </li>
            <li>
              <span className="legal-returns-app__para-title">(b) Access.&nbsp;</span>
              <span>You may access the Personal Data we hold about you at any time by contacting us directly.</span>
            </li>
            <li>
              <span className="legal-returns-app__para-title">(c) Amend.&nbsp;</span>
              <span>You can also contact us to update or correct any inaccuracies in your Personal Data.</span>
            </li>
            <li>
              <span className="legal-returns-app__para-title">(d) Move.&nbsp;</span>
              Your Personal Data is portable – i.e. you have the flexibility to move your data to other service
              providers as you wish.
            </li>
            <li>
              <span className="legal-returns-app__para-title">(e) Erase and forget.&nbsp;</span>
              In certain situations, for example, when the Personal Data we hold about you is no longer relevant or is
              incorrect, you can request that we erase your data.
            </li>
          </ul>

          <p>
            <span>You may contact us via email at&nbsp;</span>
            <a href="mailto:customercare@safedome.com">
              <span>customercare@safedome.com</span>
            </a>
            <span>
              {' '}
              or via mail at Maxwell Forest Pty Ltd, Lvl 1, 85 William Street, Darlinghurst, Sydney, Australia, 2010 any
              time for any other questions you may have about your personal data and our use of such personal data.
            </span>
          </p>

          <p>
            <span>
              If you wish to exercise any of these rights, please contact us using the details in the section titled
              Questions; Contacting Company; Reporting Violations above. In your request, please make clear: what
              Personal Data is concerned, and (ii) which of the above rights you would enforce. For your protection, we
              may only implement requests with respect to the Personal Data associated with the particular email address
              that you use to send us your request, and we may need to verify your identity before implementing your
              request. We will try to comply with your requests as soon as reasonably practicable, and in any event,
              within one month of your request. Please note that we may need to return certain information for
              record-keeping purposes and/or to complete any transactions that you began prior to requesting such change
              or deletion.
            </span>
          </p>

          <h4>Retention of Your Personal Data.</h4>
          <br />
          <p>
            <span>
              We will only retain your personal data as long as reasonably required for you to use the
              Site/Application(s) and/or to provide you with the Services unless a longer retention period is required
              or permitted by law (for example, for regulatory purposes).
            </span>
          </p>

          <p>
            By using our website, you (the visitor) agree to allow third parties to process your IP address, in order to
            determine your location for the purpose of currency conversion. You also agree to have that currency stored
            in a session cookie in your browser (a temporary cookie which gets automatically removed when you close your
            browser). We do this in order for the selected currency to remain selected and consistent when browsing our
            website so that the prices can convert to your (the visitor) local currency.
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default LegalPrivacy;
